<template>
  <NavBarVue />
  <div class="pricing-div">
    <!-- Heading Section and Enquiry Section  -->

    <div class="price-heading-div">
      <div class="heading-text">
        <h3>Social Media Marketing Plans</h3>
        <h5>We Will Help Your Company Scale Faster</h5>
      </div>
      <div class="border-div"></div>
      <div class="enquiry-div">
        <p>For any enquiry contact below number</p>
        <button type="button">+91 9426734485</button>
      </div>
    </div>

    <!-- Plan Card Section  -->

    <div class="price-plan-container">
      <!-- Basic Plan Card  -->
      <div class="price-plans-div">
        <div class="plan-card-div">
          <div class="plan-card-heading">Basic Plan</div>
          <div class="plan-card-services">
            <div class="plan-card-data-heading">Available Services</div>
            <div class="plan-services-list">
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Facebook</h4>
                <h5>1000 Followers</h5>
              </div>
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Instagram</h4>
                <h5>1000 Followers</h5>
              </div>
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Youtube</h4>
                <h5>1000 Subscribers</h5>
              </div>
            </div>
            <div class="plan-card-desc">
              <span>*</span>
              <p>For Youtube Client Must Provide Videos</p>
            </div>
          </div>
          <hr />
          <div class="plan-card-creative-post">
            <div class="plan-card-data-heading">Creative Post</div>
            <div class="plan-card-post-item">
              <img src="./assets/check_circle.svg" alt="check-img" />
              <h4>5 - Post/Per Month Minimum 50 Likes.</h4>
            </div>
          </div>
          <hr />
          <div class="plan-card-price-section">
            <div class="first-section">
              <div class="price-div">
                <h3>₹ 30,000</h3>
                <div class="cross-div">
                  <img src="./assets/Group_45139.svg" alt="cross-img" />
                </div>
              </div>
              <div class="discount-label">
                <h3>67% OFF</h3>
              </div>
            </div>

            <div class="second-section">
              <div class="price-row">
                <h3>₹ 10,000</h3>
                <p>(Social Media Cost)</p>
              </div>
              <div class="price-row">
                <h3 id="line-price">₹ 2,000</h3>
                <p>( 20% Redjinni Charges)</p>
              </div>
              <div class="price-row">
                <h3 id="total-price">₹ 12,000</h3>
                <p>+ GST 18%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gold Plan Card  -->
      <div class="gold-plan-div">
        <div class="price-plans-div" id="gold-plan-div">
          <div class="plan-card-div">
            <div class="plan-card-heading">Gold Plan</div>
            <div class="plan-card-services">
              <div class="plan-card-data-heading">Available Services</div>
              <div class="plan-services-list">
                <div class="plan-services-list-item">
                  <img src="./assets/check_circle.svg" alt="check-img" />
                  <h4>Facebook</h4>
                  <h5>5000 Followers</h5>
                </div>
                <div class="plan-services-list-item">
                  <img src="./assets/check_circle.svg" alt="check-img" />
                  <h4>Instagram</h4>
                  <h5>5000 Followers</h5>
                </div>
                <div class="plan-services-list-item">
                  <img src="./assets/check_circle.svg" alt="check-img" />
                  <h4>Youtube</h4>
                  <h5>5000 Subscribers</h5>
                </div>
              </div>
              <div class="plan-card-desc">
                <span>*</span>
                <p>For Youtube Client Must Provide Videos</p>
              </div>
            </div>
            <hr />
            <div class="plan-card-creative-post">
              <div class="plan-card-data-heading">Creative Post</div>
              <div class="plan-card-post-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>10 - Post/Per Month Minimum 50 Likes.</h4>
              </div>
            </div>
            <hr />
            <div class="plan-card-price-section">
              <div class="first-section">
                <div class="price-div">
                  <h3>₹ 45,000</h3>
                  <div class="cross-div">
                    <img src="./assets/Group_45139.svg" alt="cross-img" />
                  </div>
                </div>
                <div class="discount-label">
                  <h3>33% OFF</h3>
                </div>
              </div>

              <div class="second-section">
                <div class="price-row">
                  <h3>₹ 25,000</h3>
                  <p>(Social Media Cost)</p>
                </div>
                <div class="price-row">
                  <h3 id="line-price">₹ 5,000</h3>
                  <p>( 20% Redjinni Charges)</p>
                </div>
                <div class="price-row">
                  <h3 id="total-price">₹ 30,000</h3>
                  <p>+ GST 18%</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="best-seller-div">
          <img src="./assets/Group_4666.svg" alt="star-img" />
          <h3>Best Seller</h3>
        </div>
      </div>

      <!-- Diamond Plan Card  -->
      <div class="price-plans-div">
        <div class="plan-card-div">
          <div class="plan-card-heading">Diamond Plan</div>
          <div class="plan-card-services">
            <div class="plan-card-data-heading">Available Services</div>
            <div class="plan-services-list">
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Facebook</h4>
                <h5>10000 Followers</h5>
              </div>
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Instagram</h4>
                <h5>10000 Followers</h5>
              </div>
              <div class="plan-services-list-item">
                <img src="./assets/check_circle.svg" alt="check-img" />
                <h4>Youtube</h4>
                <h5>10000 Subscribers</h5>
              </div>
            </div>
            <div class="plan-card-desc">
              <span>*</span>
              <p>For Youtube Client Must Provide Videos</p>
            </div>
          </div>
          <hr />
          <div class="plan-card-creative-post">
            <div class="plan-card-data-heading">Creative Post</div>
            <div class="plan-card-post-item">
              <img src="./assets/check_circle.svg" alt="check-img" />
              <h4>20 - Post/Per Month Minimum 50 Likes.</h4>
            </div>
          </div>
          <hr />
          <div class="plan-card-price-section">
            <div class="first-section">
              <div class="price-div">
                <h3>₹ 80,000</h3>
                <div class="cross-div">
                  <img src="./assets/Group_45139.svg" alt="cross-img" />
                </div>
              </div>
              <div class="discount-label">
                <h3>25% OFF</h3>
              </div>
            </div>

            <div class="second-section">
              <div class="price-row">
                <h3>₹ 50,000</h3>
                <p>(Social Media Cost)</p>
              </div>
              <div class="price-row">
                <h3 id="line-price">₹ 10,000</h3>
                <p>( 20% Redjinni Charges)</p>
              </div>
              <div class="price-row">
                <h3 id="total-price">₹ 60,000</h3>
                <p>+ GST 18%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Customized Plan Card  -->
      <div class="price-plans-div">
        <div class="plan-card-div">
          <div class="plan-card-heading">Customized Plan</div>
          <div class="plan-first-row-div">
            <h3>As Per Client Budget</h3>
            <p>Our Charges - 20% Of Social Media Cost +18% GST.</p>
          </div>
          <hr />
          <div class="plan-second-row-div">
            <h4>The Minimum Spend Must Be</h4>
            <h4><span>Rs. 1,00,000</span> / Per Month.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterVue />
</template>

<script>
import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";

import "./style/PricingPageCSS.css";

export default {
  name: "PricingPage",
  components: {
    NavBarVue,
    FooterVue,
  },
};
</script>

<style scoped>
</style>