import { createWebHistory, createRouter } from "vue-router";
import MenuBar from "./components/MenuBar.vue";
import ContactUs from "./components/ContactUs.vue";
import PricingPage from "./components/PricingPage/PricingPage.vue";
import PromotionPage from "./components/PromotionPage/PromotionPage.vue";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage.vue";
import ServicePage from "./components/ServicesPage/ServicesPage.vue";
import RaiseTicket from "./components/RaiseTicket/RaiseTicket.vue";
import PaymentPage from "./components/PaymentPage/PaymentPage.vue";

// IB Talks and Blogs Components

import IB_Talks_Page from "./components/IB_Talks_And_Blogs/IB_Talks_Page.vue";
import All_Blogs from "./components/IB_Talks_And_Blogs/All_Blogs.vue";
import Blogs from "./components/IB_Talks_And_Blogs/Blogs.vue";

// Tutorial Page Component

import TutorialPage from "./components/TutorialPage/TutorialPage.vue";

const routes = [
	// Tutorial Page routes
	{
		path: "/tutorialPage",
		component: TutorialPage,
		name: "TutorialPage",
	},
	// IB Talks and Blogs routes
	{
		path: "/IBTalks",
		component: IB_Talks_Page,
		name: "IBTalks",
	},
	{
		path: "/AllBlogs",
		component: All_Blogs,
		name: "AllBlogs",
	},
	{
		path: "/blogs/:id",
		component: Blogs,
		name: "Blogs",
	},
	{
		name: "MenuBar",
		path: "/",
		component: MenuBar,
	},
	{
		name: "ContactUs",
		path: "/contactus",
		component: ContactUs,
	},
	{
		name: "PricingPage",
		path: "/pricingpage",
		component: PricingPage,
	},
	{
		name: "PromotionPage",
		path: "/promotionPage",
		component: PromotionPage,
	},
	{
		name: "AboutUsPage",
		path: "/aboutusPage",
		component: AboutUsPage,
	},
	{
		name: "ServicePage",
		path: "/servicesPage",
		component: ServicePage,
	},
	{
		name: "RaiseTicket",
		path: "/raiseTicket",
		component: RaiseTicket,
	},
	{
		name: "PaymentPage",
		path: "/paymentPage",
		component: PaymentPage,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});
export default router;
