<template>
  <NavBarVue />
  <div class="raise-ticket-container">
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
      ref="ticket_btn"
      style="display: none"
    >
      Launch Raise Ticket Form Modal
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- <div class="modal-header">
        </div> -->
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="position: absolute; top: 20px; right: 25px"
              @click="this.$router.push('/')"
            ></button>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="position: absolute; top: 20px; right: 25px; display: none"
              ref="ticket_close_btn"
            ></button>
            <div class="raise-ticket-main-div">
              <div class="raise-ticket-heading">
                <h3>Raise a Ticket</h3>
                <h4>Please provide the details of the problem</h4>
              </div>

              <div class="raise-ticket-form-div">
                <div class="form-row-1">
                  <div class="form-input-filed">
                    <label for="first_name">First Name</label>
                    <input
                      type="text"
                      id="first_name"
                      placeholder="First Name"
                      v-model="first_name"
                    />
                  </div>
                  <div class="form-input-filed">
                    <label for="last_name">Last Name</label>
                    <input
                      type="text"
                      id="last_name"
                      placeholder="Last Name"
                      v-model="last_name"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <label for="email">Email Address</label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    v-model="email"
                  />
                </div>
                <div class="form-row">
                  <label for="number">Mobile Number</label>
                  <input
                    type="text"
                    id="number"
                    placeholder="Mobile Number"
                    v-model="mobile_number"
                  />
                </div>
                <div class="form-row">
                  <label for="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    v-model="address"
                  />
                </div>
                <div class="form-row-1">
                  <div class="form-input-filed">
                    <label for="country">Country</label>
                    <!-- <input
                      type="text"
                      id="country"
                      placeholder="Country"
                      v-model="country"
                    /> -->
                    <select v-model="country" @change="loadStates" id="country">
                      <option disabled value="">Select Country</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-input-filed">
                    <label for="state">State</label>
                    <!-- <input
                      type="text"
                      id="state"
                      placeholder="State"
                      v-model="state"
                    /> -->
                    <select v-model="state" @change="loadCities" id="state">
                      <option disabled value="">Select State</option>
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row-1">
                  <div class="form-input-filed">
                    <label for="city">City</label>
                    <!-- <input
                      type="text"
                      id="city"
                      placeholder="City"
                      v-modal="city"
                    /> -->
                    <select v-model="city" id="city">
                      <option disabled value="">Select City</option>
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-input-filed">
                    <label for="pincode">Pincode</label>
                    <input
                      type="text"
                      id="pincode"
                      placeholder="954 321"
                      v-model="pincode"
                    />
                  </div>
                </div>

                <div class="form-photo-upload">
                  <label for="photo">Upload Screenshot</label>
                  <div class="upload-input-div">
                    <!-- @change="handleFileSelectDocuments" -->
                    <div
                      class="upload-input-div-1"
                      :class="{ 'full-width': selectedFiles.length > 0 }"
                    >
                      <input
                        type="file"
                        id="photo"
                        @change="handleFileSelectDocuments"
                      />
                      <img src="./assets/download.svg" alt="file-img" />
                      <h3>Upload a file</h3>
                      <h4>Drag and Drop files here</h4>
                    </div>
                    <div
                      class="upload-input-div-2"
                      v-if="selectedFiles.length > 0"
                    >
                      <p class="">Selected Files:</p>
                      <ul class="">
                        <li
                          v-for="(file, index) in selectedFiles"
                          :key="index"
                          class=""
                        >
                          <div class="selected-list-item">
                            <span>{{ file.name }}</span>
                            <button
                              class="btn-close"
                              @click="removeFile(index)"
                            ></button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <label for="desc">Describe the Problem</label>
                  <textarea
                    cols="20"
                    rows="5"
                    type="text"
                    id="desc"
                    placeholder="Type here...…."
                    v-model="descProblem"
                  />
                </div>

                <div class="form-button-div">
                  <button type="button" @click="submitForm">SUBMIT</button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
          </div> -->
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#thankyouModal"
      ref="thankyou_btn"
      style="display: none"
    >
      Launch Thank You Modal
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="thankyouModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered custom-max-width">
        <div class="modal-content">
          <!-- <div class="modal-header">
        </div> -->
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="position: absolute; top: 20px; right: 25px"
              @click="this.$router.push('/')"
            ></button>
            <div class="thank-you-pop-up">
              <div class="thank-you-img-div">
                <img src="./assets/Group_89.svg" alt="thank-you-img" />
              </div>
              <div class="thank-you-content">
                <h3>Thank You</h3>
                <p>Your Ticket has been raised.</p>
                <h4>We will resolve your issue in 24 hours.</h4>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <FooterVue />
</template>

<script>
import "./style/RaiseTicket.css";
import FooterVue from "../Footer/Footer.vue";
import NavBarVue from "../NavBar/NavBar.vue";
import Swal from "sweetalert2";

import axios from "axios";

export default {
  name: "RaiseTicket",
  components: {
    NavBarVue,
    FooterVue,
  },
  data() {
    return {
      countries: [],
      states: [],
      cities: [],

      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      address: "",
      country: "101",
      state: "",
      city: "",
      pincode: "",
      descProblem: "",
      selectedFiles: [],
      projectName: "Redjinni",

      apiUrl: "https://riseticket.infinitybrains.com/public/api/addproject",
    };
  },
  methods: {
    handleFileSelectDocuments(event) {
      const files = event.target.files;
      // this.selectedFiles = event.target.files;

      if (files && files.length > 0) {
        //   // Handle the files (validate, store, etc.)
        //   console.log("Selected Files:", files);

        //   // Clear previous selection
        //   // this.selectedFiles = [];
        for (let i = 0; i < files.length; i++) {
          this.selectedFiles.push(files[i]);
          // console.log("File Details:", this.selectedFiles[i]);
          // console.log("File type:", this.selectedFiles[i].type);
        }
      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },

    submitForm() {
      const formData = new FormData();

      const fullName = this.first_name + " " + this.last_name;

      formData.append("name", fullName);
      formData.append("email", this.email);
      formData.append("mobileno", this.mobile_number);
      formData.append("address", this.address);
      formData.append("country", this.country);
      formData.append("state", this.state);
      formData.append("city", this.city);
      formData.append("pincode", this.pincode); // Uncomment if needed
      formData.append("issue", this.descProblem);
      formData.append("project_name", this.projectName);

      // // Append each selected file to FormData
      for (let i = 0; i < this.selectedFiles.length; i++) {
        // formData.append(`raise_ticket${[i]}`, this.selectedFiles[i]);
        formData.append("image", this.selectedFiles[i]);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };
      // Make an API call to your backend
      axios
        .post(this.apiUrl, formData, {
          headers,
        })
        .then(() => {
          // Handle the success response
          // console.log("Response of Raise Ticket: ", response);
          const ticket_close_btn = this.$refs.ticket_close_btn;
          const thankyouPopUp = this.$refs.thankyou_btn;

          ticket_close_btn.click();
          setTimeout(() => {
            thankyouPopUp.click();
          }, 200);

          this.resetFormData();
        })
        .catch((error) => {
          // Handle the error
          console.error("Axios Error:-", error);
          Swal.fire({
            title: "Something Went Wrong!!",
            text: `Try again after some time !!`,
            icon: "error",
          });
        });
    },

    resetFormData() {
      this.first_name = "";
      this.last_name;
      this.email = "";
      this.mobile_number = "";
      this.address = "";
      this.country = "";
      this.state = "";
      this.city = "";
      this.pincode = "";
      this.descProblem = "";
      this.selectedFiles = [];
    },

    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;

        while (shouldContinue) {
          const response = await axios.get(
            `https://api.garbajockey.com/api/countries?page=${page}`
          );
          // console.log("Response:", response); // Log the response

          const countries = response.data && response.data.data;

          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }

        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },

    async loadStates() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/states_of_country?country_id=${this.country}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.state = ""; // Reset state value
        this.city = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },

    async loadCities() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/cities_of_state?state_id=${this.state}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.city = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },
  },
  mounted() {
    this.loadCountries();
    this.loadStates();

    const btn = this.$refs.ticket_btn;
    btn.click();
  },
};
</script>

<style scoped></style>